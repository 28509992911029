const setupDefaultCoreDependencies = require('./default-dependencies').default;

const Log = require('./log');
const Err = require('utils/src/error');
const _ = require('./utils/legacy');
const Function = require('./function');
const IAPI = require('./api-client-abstract').default;
const ISession = require('./i-session').default;
const Registry = require('./registry').default;
const FTI = require('./fti');
const ApplicationInfo = require('./application-info').default;
const EventInterface = require('./event-interface');
const IGraphileon = require('./i-graphileon').default;
const IFactory = require('./i-factory').default;
const GraphStyles = require('./graph/graph-styles').default;
const deferredPromise = require('core/src/utils/deferred-promise');
const APIFunction = require('core/src/functions/api');
const Profiler = require('utils/src/profiler');
const Language = require('./language').default;
const {isTrue} = require('core/src/utils/validation');
const APIClientAbstract = require('core/src/api-client-abstract').default;
const CodeEvaluator = require('core/src/code-evaluator');
const Edition = require('core/src/edition/index').default;
const {isRunningNodeJs} = require("core/src/utils/platform");
const ActivityLog = require('core/src/activity-log').default;

const log = Log.instance('core');

class Graphileon extends IGraphileon {
	constructor(dependencies, config = {}) {
		super();

		this.ready = false;
		this.config = config;

		// Setup dependencies
		this.dependencies = setupDefaultCoreDependencies(dependencies); // default dependencies for core
		this.dependencies.set(IGraphileon, this); // access to Graphileon core for those who need it

		// Grab what we need
		this.factory = this.dependencies.get(IFactory);
		this.api = this.dependencies.get(IAPI);
		this.session = this.dependencies.get(ISession);
		this.registry = this.dependencies.get(Registry);
		this.fti = this.dependencies.get(FTI);
		this.appInfo = this.dependencies.get(ApplicationInfo);
		this.styles = this.dependencies.get(GraphStyles);
		this.language = this.dependencies.get(Language);
		this.codeEvaluator = this.dependencies.get(CodeEvaluator);

		this.isServerSide = isRunningNodeJs();

		if (! this.isServerSide){
			this.codeEvaluator.setPublic('localStorage', localStorage)
		}

		this._eventInterface = new EventInterface();
		this.on = this._eventInterface.getOnMethod();
		this.fire = this._eventInterface.getFireMethod();
		this._eventInterface.onEvent(this.onEvent.bind(this));

		this.fti.on(Function.Event.Out.ERROR, this.handleFunctionErrorEvent.bind(this));
		this.fti.on(Function.Event.Out.TRIGGER, this.handleFunctionTriggerEvent.bind(this));

		this.appInfo.application = {
			name: config.application || 'default'
		};

		this.api.setConfig({
			server: _.get(config, 'server', '')
		});
		this.api.on(APIClientAbstract.Event.LOADING_STARTED, () => {
			this.fire(IGraphileon.Event.LOADING_STARTED);
		});
		this.api.on(APIClientAbstract.Event.LOADING_FINISHED, () => {
			this.fire(IGraphileon.Event.LOADING_FINISHED);
		})

		this.loaded = false;
	}
}
Graphileon.IA = 'IA_'; // label prefix

const defaultListenerEvent = function(data, event) {
	return {
		type: event,
		data: data
	};
};
const errorListenerEvent = function(data, event) {
	return {
		type: 'error',
		data: _.extend({}, data, {errorType: event})
	};
};

Graphileon.ListenableEvents = {
	[Graphileon.Event.ENVIRONMENT_LOADED]: defaultListenerEvent,
	[Graphileon.Event.LOGIN]: defaultListenerEvent,
	[Graphileon.Event.LOGOUT]: defaultListenerEvent,
	[Graphileon.Event.DASHBOARD_LOADED]: defaultListenerEvent,
	[Graphileon.Event.ERROR]: errorListenerEvent,
	[Graphileon.Event.ERROR_CONFIGURATION]: errorListenerEvent,
	[Graphileon.Event.GET_ORIGIN_FUNCTION]: defaultListenerEvent,
	[Graphileon.Event.LANGUAGE_CHANGED]: defaultListenerEvent,
	[Graphileon.Event.ACCOUNT_RECOVER]: defaultListenerEvent,
	[Graphileon.Event.USER_VIEW_ACCOUNT_BUTTON]: defaultListenerEvent,
	[Graphileon.Event.USER_VIEW_INFO_BUTTON]: defaultListenerEvent,
	[Graphileon.Event.ERROR_PERMISSION_DENIED]: defaultListenerEvent

};

Graphileon.splitUserTeamResources = function(resources) {
	// Split user shortcuts into user and team
	let userResources = [];
	let teamResources = [];
	for(let i = resources.length-1; i >= 0; i--) {
		if(resources[i].path.relations.length > 1) {
			teamResources.push(resources[i]);
		} else {
			userResources.push(resources[i]);
		}
	}

	return {user: userResources, team: teamResources};
};

/**
 * Set log levels for different modules.
 * @param {object|Graphileon} levels	Keys of the objects are names of the Log instances, values are the log levels.
 * 										If an InterActor instance is provided, the environment log levels of that
 * 										instance will be used.
 * (capitalized strings).
 */
Graphileon.setLogLevels = function(levels) {
	if(levels instanceof Graphileon) {
		levels = levels.appInfo.logLevels;
	}
	if(!_.isPlainObject(levels)) {
		log.warn("Invalid log levels.");
		return;
	}

	log.log(`Default log level: ${levels.default}.`);
	for(let instance in levels) {
		if(instance === 'default') continue;
		let level = _.upperCase(levels[instance]);

		log.log(`Log level for '${instance}' set to '${level}'.`);
		Log.instance(instance).setLevel(Log.Level[level]);
	}
	Log.setLevel(Log.Level[levels.default]);
};

Graphileon.prototype.handleServerError = function(error) {
	const configurationErrors = [
		'ApplicationStoreNotFound',
		'NotInstalled'
	];
	if(configurationErrors.indexOf(error.code) >= 0) {
		this.fire(Graphileon.Event.ERROR_CONFIGURATION);
	}

	log.error("Server error.", error);
	this.fire(Graphileon.Event.ERROR_SERVER, error);
};

Graphileon.prototype.handlePermissionError = function(error) {
	
	log.error("Permission error.", error);
	this.fire(Graphileon.Event.ERROR_PERMISSION_DENIED, error);
};

Graphileon.prototype.handleFunctionErrorEvent = function(event) {
	this.fire(Graphileon.Event.ERROR_FUNCTION, event.error);
};
Graphileon.prototype.handleFunctionTriggerEvent = function(event) {
	this.fire(Graphileon.Event.FUNCTION_TRIGGER, event);
};

// EventListeners
Graphileon.prototype.onEvent = function(data, event){
	// Cannot run EventListeners before application was loaded
	if ( ! this.loaded ) return;

	let eventObjectFunction = Graphileon.ListenableEvents[event];
	if(_.isFunction(eventObjectFunction)) {
		this.runEventListenerFunctions(eventObjectFunction(data, event));
	}
};

Graphileon.prototype.executeTriggers = function(triggers, eventData) {
	const map = {};
	_.forEach(triggers, triggerData => {
		const trigger = this.factory.createTriggerFromRelation(triggerData);

		eventData = _.ensure(eventData, _.isObject, {});

		map[trigger.id] = trigger.execute(eventData);
	});
	return _.waitForAll(map);
};

Graphileon.prototype.executeFunction = function(functionData, data) {
	return this.factory.executeFunction(functionData, data);
};

Graphileon.prototype.requestAvailableDashboards = function(refresh = false) {
	return new Promise((resolve, reject) => {
		if (!this.session.isLoggedIn()) {
			return reject(new _.Error({
				message: this.language.translate('No user logged in.'),
				code: Graphileon.ErrorCode.NOT_LOGGED_IN
			}));
		}

		if(!refresh && !this.appInfo.dashboards) {
			log.log("Available dashboards already retrieved; loading from memory.");
			return resolve(this.appInfo.dashboards);
		}

		this.api.requestDashboards()
			.then((dashboards) => {
				log.log("Retrieved available dashboards.");

				// Evaluate dashboard names
				_.forEach(dashboards, dashboard =>
					dashboard.properties.name = this.evaluate(dashboard.properties.name)
				);
				this.appInfo.dashboards = dashboards;
				resolve(this.appInfo.dashboards);
			})
			.catch((err) => {
				log.error("Could not retrieve available dashboards.");
				reject(err);
			});
	});
};

/**
 * Quick code evaluation with global data `(@)` in context.
 * @param {string} code
 * @returns {*} Evaluated value, or input value if evaluation failed.
 */
Graphileon.prototype.evaluate = function(code) {
	try {
		code = CodeEvaluator.replaceCodePlaceholders(code, {
			'(@)': '_global'
		});
		return this.codeEvaluator.evaluate('let evaluated = ' + code, {
			'_global': this.fti.getGlobalData(this.session)
		}).evaluated;
	} catch(e) {
		return code;
	}
};

Graphileon.prototype.loadDefaultDashboard = async function() {
	log.log("Loading default dashboard...");
	try {
		const dashboards = await this.requestAvailableDashboards();
		if(dashboards.length > 0) {
			return this.loadDashboard(dashboards[0].id);
		}
	} catch(e) {
		if(e.code === Graphileon.ErrorCode.NOT_LOGGED_IN) {
			log.log("Not logged in; not loading dashboard.");
			this.fire(Graphileon.Event.LOGIN_REQUIRED);
			return false;
		}
		throw e;
	}
	return false;
};

Graphileon.prototype.closeDashboard = function() {
	log.log("Closing dashboard.");
	const dashboard = this.appInfo.dashboard;
	this.appInfo.dashboard = null;
	this.fti.closeAllFunctionInstances([Function.StayAlive.DASHBOARD, Function.StayAlive.NONE]);

	setTimeout(()=>{ // Functions won't be closed before next event loop
		this.fire(Graphileon.Event.DASHBOARD_CLOSED, dashboard);
	});
};

/**
 *
 * @param {number|string} dashboardID	ID or iaName of the dashboard.
 * @param [bookmarkName]
 * @param {object} [params]	Query string params from the URL.
 * @return {*}
 */
Graphileon.prototype.loadDashboard = function(dashboardID, bookmarkName, params) {
	const promise = new Promise(async (resolve, reject) => {
		const throwError = (error) => {
			error.data = dashboardID;
			this.fire(IGraphileon.Event.ERROR_DASHBOARD_LOAD_FAILED, error);
			reject(error);
		};

		// Validation
		let check = _.validate("loadDashboard", {
			dashboardID: [dashboardID, _.isStringOrNumber(dashboardID), this.language.translate('Must be valid node ID.')],
			params: [params, _.isObject(params), {default: {}, warn: _.def}]
		}, this.language.translate('Could not load Dashboard.'));
		if(! check.isValid()) {
			return throwError(check.createError());
		}
		let valid = check.getValue();

		log.log(`Loading Dashboard '${dashboardID}'...`);

		// Find by iaName in memory
		let dashboard = _.find(this.appInfo.dashboards, dashboard => {
			return dashboard.properties.iaName === dashboardID;
		});
		// Find by ID in memory
		if(!dashboard) {
			dashboard = _.find(this.appInfo.dashboards, dashboard => dashboard.id == dashboardID);
		}
		// Ask server; maybe it's not one of the user's dashboards but we are allowed to load it?
		if(!dashboard) {
			try {
				dashboard = await this.api.requestDashboardById(dashboardID);
			} catch(e) {
				if(e.code === Graphileon.ErrorCode.NOT_LOGGED_IN) return false;

				return throwError(new _.Error(this.language.translate("Could not load dashboard: {{dashboardID}}", {dashboardID}) , e));
			}
		}

		if (! dashboard) {
			return throwError(new _.Error({
				message: this.language.translate('Could not find dashboard: {{dashboardID}}', {dashboardID}),
				code: Graphileon.ErrorCode.NOT_FOUND,
				data: dashboardID
			}));
		}

		this.closeDashboard();

		const requests = {
			DashboardShortcuts: this.api.requestDashboardShortcuts(dashboard.id),
			DashboardStartTriggers: this.api.requestDashboardStartTriggers(dashboard.id),
		};

		if (bookmarkName) {
			log.log(`Loading bookmark '${bookmarkName}'...`);
			requests.DashboardBookmarks = this.api.requestBookmarks(dashboard.id, bookmarkName);
		}

		_.waitForAll(requests, 5000)
			.then((map) => {
				let shortcuts = [];
				let startTriggers = [];
				let bookmarks = [];
				if(_.isArray(map["DashboardShortcuts"])) {
					shortcuts = map["DashboardShortcuts"];
					// Evaluate shortcut names
					this.appInfo.shortcuts.dashboard = _.map(shortcuts, shortcut => (
						{...shortcut, name: this.evaluate(shortcut.name)}
					));
				}
				if(_.isArray(map["DashboardStartTriggers"])) {
					startTriggers = map["DashboardStartTriggers"];
				}
				if(_.isArray(map["DashboardBookmarks"])) {
					bookmarks = map["DashboardBookmarks"];
				}
				log.log("Dashboard content loaded", {startTriggers, shortcuts, bookmarks});

				let dashboardData = {
					id: dashboard.id,
					iaName: dashboard.properties.iaName,
					name: this.evaluate(dashboard.properties.name),
					icon: dashboard.properties.icon,
					shortcuts: _.map(shortcuts, shortcut => (
						{...shortcut, name: this.evaluate(shortcut.name)} // evaluate shortcut names
					)),
					startTriggers,
					bookmarks
				};
				this.appInfo.dashboard = dashboardData;
				this.appInfo.currentPage = {
					dashboard: dashboard.id,
					bookmark: bookmarkName,
					params: params
				};
				this.fti.setDashboard(dashboardData);

				// Execute START (and BOOKMARK) triggers
				log.log("Dashboard executing START and BOOKMARK Triggers...");
				this.executeTriggers(startTriggers.concat(bookmarks), {
					_path: { dashboard: dashboardData },
					user: this.session.getUser(),
					url: valid.params
				});

				resolve(dashboardData);
				setTimeout( // don't catch errors from handlers
					()=>this.fire(Graphileon.Event.DASHBOARD_LOADED, dashboardData)
				);
			})
			.catch((map) => {
				const error = new _.Error({
					message: this.language.translate('Could not load dashboard info'),
					errorMap: map,
					data: {dashboardID}
				});
				log.error(error.message, error);
				throwError(error);
				this.fire(Graphileon.Event.ERROR, error);
			});
	});

	return deferredPromise(promise);
};

Graphileon.prototype.reloadDashboard = function() {
	if(!this.appInfo.currentPage.dashboard) {
		return;
	}
	this.closeDashboard();
	this.loadDashboard(this.appInfo.currentPage.dashboard, this.appInfo.currentPage.bookmark, this.appInfo.currentPage.params);
};

/**
 * Attempt a user login.
 * @param name
 * @param pass
 * @return {*}
 */
Graphileon.prototype.login = async function(name, pass, tfaToken) {
	let automatic = false;
	if(name === undefined && pass === undefined) {
		automatic = true;
	}
	let user, token;
	try {
		const response = await this.session.authenticate(name, pass, undefined, tfaToken);
		user = response.user;
		token = response.token;
	} catch (error) {
		if (error.code === ISession.Error.TFA_VALIDATION_REQUIRED) {
			this.fire(Graphileon.Event.TFA_TOKEN_REQUIRED, true);
			return;
		}
		if (error.code === ISession.Error.TFA_VALIDATION_INVALID_TOKEN) {
			throw new Err(this.language.translate(error.message));
		}
	}

	if(!user) throw new Err(this.language.translate('Authentication failed.'));

	log.log(`Logged in as '${user.properties.name}'.`);
	let devMode = isTrue(_.get(user, 'properties.devMode'));

	this.caching = !devMode;
	this.api.setCaching(this.caching);
	this.api.setToken(token);

	this.fire(Graphileon.Event.LOGIN, {user, automatic});

	return user;
};

/**
 * Log off the current user.
 */
Graphileon.prototype.logout = function() {
	this.session.logout();
};

Graphileon.prototype.isLoggedIn = function() {
	return this.session.isLoggedIn();
};

Graphileon.prototype.getUser = function() {
	return this.session.getUser();
};

Graphileon.prototype.isEnvironmentLoaded = function() {
	return this.appInfo.version !== undefined;
};

/**
 * Get environment info, such as available stores, software version, Welcome Functions and updates.
 * @return {*}
 */
Graphileon.prototype.loadEnvironment = async function() {
	let info;
	try {
		info = await this.api.getEnvironmentInfo();
	} catch(error) {
		this.session.clear();

		let configErrors = [406, 407, 408];
		if(configErrors.indexOf(error.code) >= 0) {
			this.fire(Graphileon.Event.ERROR_CONFIGURATION, error);
		}

		if (error.code === 401) {
			this.fire(Graphileon.Event.SESSION_EXPIRED, error);
		}

		if(error.code === 'ConnectionFailed') {
			this.fire(Graphileon.Event.ERROR_CONNECTION, error);
		}

		throw error;
	}

	// Handle results
	const environment = info.environment;
	log.info("Retrieved environment info.");

	Edition.setCurrent(environment.edition);
	
	this.appInfo.version = info.version;
	this.appInfo.configurationVersion = info.configurationVersion;
	this.appInfo.revision = info.revision;
	this.appInfo.googleMapsKey = environment.googleMapsKey;
	this.appInfo.activityLog = environment.activityLog;
	this.appInfo.stores = environment.stores;
	this.appInfo.logLevels = environment.logLevels;
	this.appInfo.sessionTimeout = environment.sessionTimeout; // sessionTimeout should not really be a property of 'environment', rather of 'info'
	this.appInfo.sessionTimeoutWarnBefore = environment.sessionTimeoutWarnBefore;
	this.appInfo.edition = Edition.current.code;
	this.appInfo.accountRecovery = environment.accountRecovery;
	this.appInfo.application.name = environment.appName;
	this.appInfo.userView = {
		infoButton: _.get(environment, 'userView.infoButton', false),
		userButton: _.get(environment, 'userView.userButton', false),
	}

	this.api.setRequestTimeout(environment.requestTimeoutMs);

	// TODO: backend should provide its own url
	this.appInfo.backend = info.backend;
	this.appInfo.debugScreen = info.debugScreen;

	this.fti._apiKeys = environment.apiKeys;

	this.loaded = true;
	this.fire(Graphileon.Event.ENVIRONMENT_LOADED, environment);

	return environment;
};

Graphileon.prototype.loadLicenseLimits = async function() {
	try {
		this.appInfo.license.limits = await this.api.executeRequest('/license/limits');
	}
	catch(err) {
		throw err;
	};
}

Graphileon.prototype.getLanguages = function() {
	return _.reduce(
		this.language.getLanguages(),
		(result, language) => _.set(result, language, this.language.translate(language)),
		{}
	);
};

Graphileon.prototype.getCurrentLanguage = function() {
	return this.language.getCurrentLanguage();
};

Graphileon.prototype.changeLanguage = async function(language) {
	return this.language.changeLanguage(language);
};

Graphileon.prototype.translate = function(key, options) {
	return this.language.translate(key, options);
};

/**
 * Load shortcuts and dashboards of the current user.
 * @return {*}
 */
Graphileon.prototype.loadUserContent = function() {
	return new Promise((resolve, reject) => {
		if(!this.session.isLoggedIn()) {
			const err = new Err(this.language.translate('Cannot load user content. Not logged in.'));
			log.error(err.message);
			return reject(err);
		}

		let userLanguage = _.get(this.session, 'user.properties.language');
		if (userLanguage) {
			this.changeLanguage(userLanguage);
		}

		let shortcuts = this.api.requestUserShortcuts();
		let dashboards = this.requestAvailableDashboards(true);

		// Load style
		this.styles.loadUserStyles()
			.catch(err => {
				this.fire(Graphileon.Event.STYLE_ERROR, err);
			});

		_.waitForAll({shortcuts, dashboards})
			.then((results) => {
				// Set shortcuts
				results.shortcuts = _.map(results.shortcuts, shortcut => (
					{...shortcut, name: this.evaluate(shortcut.name)})
				);
				let split = Graphileon.splitUserTeamResources(results.shortcuts);
				this.appInfo.shortcuts.user = split.user;
				this.appInfo.shortcuts.team = split.team;

				log.log("User content loaded.");
				resolve(results);
				this.fire(Graphileon.Event.CONTENT_LOADED, results);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

Graphileon.prototype.subscribeUser = function() {
	try {
		const user = this.session.getUser();
		this.userIo = this.api.io('/user');
		this.userIo.on('connect', () => {
			log.debug('Socket Id: ', this.userIo.id);
		})
		this.userIo.on('disconnect', () => {
			log.debug("Socket disconnected");
		});
		userChangedListener = (updatedUserInfo) => {
			const updatedUserData = user && _.extend({}, 
				updatedUserInfo.properties, 
				{
					id: updatedUserInfo.id,
					name: updatedUserInfo.properties.name,
					permissions: updatedUserInfo.permissions
				}
			);

			this.fti.setGlobalData('user', updatedUserData);
		}

		userCriticalListener = () => {
			this.logout();
		}
		this.userIo.on(`user-update-${user.properties.uuid}`, userChangedListener);
		this.userIo.on(`user-critalDataUpdate-${user.properties.uuid}`, userCriticalListener);
		this.userIo.on(`user-delete-${user.properties.uuid}`, userCriticalListener);
	} catch (err) {
		console.error(err);
	}
	this.session.on(ISession.Event.LOGOUT, (user) => {
		this.userIo.off('/user', this.$translationsChangedListener);
		this.userIo.close();
	});
}
// Loop checks if user is logged-in in other window (this will trigger Graphileon start when it happens)
Graphileon.prototype.startLoopCheckLogin = function() {
	let intervalHandler = setInterval(async () => {
		if(this.session.isLoggedIn()) {
			// We already successfully logged in; don't try again
			return clearInterval(intervalHandler);
		}
		try {
			await this.login();
		}
		catch(ex) {
			return;
		}
		clearInterval(intervalHandler);
	}, 2000); // Not too often but often enough to seem responsive
}

Graphileon.prototype.initLocalization = async function() {
	this.language.on(Language.Event.LANGUAGE_CHANGED, language => {
		this.appInfo.language = language;
		this.fire(IGraphileon.Event.LANGUAGE_CHANGED, {language});
		this.reloadDashboard();
	});
	this.language.on(Language.Event.LANGUAGES_LOADED, languages => {
		this.appInfo.languages = this.getLanguages();
		this.fire(IGraphileon.Event.LANGUAGES_LOADED, this.appInfo.languages);
	});
	await this.language.initLocalization();
	
	return this.changeLanguage(this.session.getLanguage());
};

Graphileon.prototype.start = function() {
	this.starting = this._start();
	return this.starting;
};

/**
 * Starts Graphileon.
 */
Graphileon.prototype._start = async function() {
	this.session.on(ISession.Event.LOGOUT, (user) => {
		log.log("User logged out.");
		this.fire(Graphileon.Event.LOGOUT, user);
	});
	this.api.on(IAPI.Event.SERVER_ERROR, (error) => {
		this.handleServerError(error);
	});
	this.api.on(IAPI.Event.LICENSE_BROKEN, (msg) => {
		log.error("Error: License broken.", msg);
		this.fire(Graphileon.Event.ERROR_LICENSE_BROKEN, msg);
	});
	this.api.on(IAPI.Event.SESSION_EXPIRED, () => {
		// First sign of expired session, log off completely
		this.logout();
	});



	this.api.on(IAPI.Event.NO_ALLOW_RELATION, (error) => {
		this.handlePermissionError(error);
	});

	this.api.on(IAPI.Event.DENIED_RELATION, (error) => {
		this.handlePermissionError(error);
	});

	this.api.on(IAPI.Event.DENIED_EXPRESSION, (error) => {
		this.handlePermissionError(error);
	});

	this.api.on(IAPI.Event.DENIED_QUERY, (error) => {
		this.handlePermissionError(error);
	});

	this.api.on(IAPI.Event.DENIED_EXTENSION, (error) => {
		this.handlePermissionError(error);
	});

	this.api.on(IAPI.Event.INVALID_PERMISSION, (error) => {
		this.handlePermissionError(error);
	});
	// On LOGIN (whether automatically or after user input)
	this.on(Graphileon.Event.LOGIN, async () => {
		try {
			log.log("Login successful.");
			await this.loadLicenseLimits();
			await this.loadUserContent();
			if (! this.isServerSide) {
				this.subscribeUser();
			}
		} catch(error) {
			log.error("Could not load content after login.", error);
		}
	});
	this.on(Graphileon.Event.ERROR, err=> {
		log.error(err.message, err);
	});

	// Startup sequence
	const profilerEnvironment = Profiler.start('graphileon.start.environment');
	try {
		await this.initLocalization();
		await this.loadEnvironment();
		this.dependencies.set(ActivityLog, new ActivityLog(this.dependencies));
	} catch(err) {
		log.error(err);
		this.fire(Graphileon.Event.ERROR, err);
	}
	profilerEnvironment.stop();

	const profilerLogin = Profiler.start('graphileon.start.login');
	try {
		await this.login();
		log.log("Logged in automatically.");
	} catch (e) {
		log.log("Automatic login failed. Manual login required.");

		if(! this.isServerSide) {
			this.startLoopCheckLogin();
		}

		// Autologin failure is ok, just fire event
		this.fire(Graphileon.Event.AUTOLOGIN_FAILED);
		if(!(e instanceof Err)) { // possible native error
			log.error(e);
		}
	}
	profilerLogin.stop();

	if (! this.isServerSide) {
		let titleEl = document.querySelector('title');
		if (titleEl) {
			titleEl.innerText = `${this.appInfo.application.name || 'Graphileon'}`;
		} 

		const profilerDashboards = Profiler.start('graphileon.start.dashboards');
		try {
			await this.requestAvailableDashboards();
		} catch(e) {
			log.error(e);
			this.fire(Graphileon.Event.ERROR, e);
		}
		profilerDashboards.stop();
	}

	const profilerStartFunctions = Profiler.start('graphileon.start.functions');
	if('startFunctions' in this.config) {
		log.log("Starting configured start Functions...");
		this.startFunctions(this.config.startFunctions);
	}
	profilerStartFunctions.stop();
};

Graphileon.prototype.startFunctions = function(startFunctions) {
	if(startFunctions.length > 0) {
		log.info("Starting functions: " + _.map(startFunctions, 'id').join(', '));
	}
	startFunctions.forEach((func, i) => {
		const id = func.id;
		let input = func.input;
		if(_.isStringOrNumber(id)) {
			// Load Function and execute
			const request = this.factory.loadFunction({id});
			request.done(func => {
				log.info("Starting Function ", func.getId(), "with input", func.getInput());

				if(!_.isObject(input)) {
					input = {};
				}

				const trigger = this.factory.createTrigger({
					targetFunction: func,
					parameters: input
				});
				trigger.execute();
			});
			request.fail(function(response) {
				log.error("Could not load starting function: " + id, "Input:", input, "response:", response);
			});
		}
	});
};

/**
 * Call an API Function and return its response.
 * @param {string} iaName			The iaName of the API Function.
 * @param {object} input			Input to the API Function.
 * @param {boolean} [serverSide]	If set to `true`, the request is made to the server to process the call.
 * @returns {*|void}
 */
Graphileon.prototype.callAPIFunction = async function(iaName, input, serverSide = false) {
	return new Promise(async (resolve, reject) => {
		if(serverSide === true) {
			// Use APIClient to let server handle request
			try {
				const response = await this.api.callAPIFunction(iaName, input);
				resolve(response);
			} catch(e) {
				reject(e);
			}
			return;
		}

		try {
			// Load API Function
			const apiFunction = await this.factory.loadFunction({iaName});

			// Listen to response
			let responded = false;
			apiFunction.on(APIFunction.Event.Out.RESPONSE, data => {
				if(responded) {
					log.error("Response provided after error.");
					return;
				}
				responded = true;
				resolve(data);
				apiFunction.close();
			});

			// Listen to Function errors
			apiFunction.on(APIFunction.Event.Out.ERROR, error => {
				if(responded) {
					log.error("Error caught after response.", error);
					return;
				}
				responded = true;
				reject(error);
				apiFunction.close();
			});

			// Execute the API Function
			apiFunction.execute({input});
		} catch(e) {
			reject(e);
		}
	});
};

Graphileon.prototype.runEventListenerFunctions = function(data) {
	log.info('Executing event listeners for', data);
	this.api.getEventListeners()
		.done((eventListeners) => {
			_.forEach(eventListeners, (eventListener) => {
				this.factory.loadFunction(eventListener)
					.done((functionInstance) => {
						functionInstance.execute({event: data});
					});
			});
		}).fail((error) => {
			log.error('Error loading event listeners', error);
	});
};

module.exports = Graphileon;
