const $ = require('jquery');
const log = require('core/src/log').instance("client/ui/container");
const EventInterface = require('core/src/event-interface');
const _ = require('core/src/utils/legacy');
const { isjQuery } = require('client/src/utils/jquery');
const { checkMethods, checkType } = require('utils/src/validation');
const VueView = require('./vue-view').default;
const ViewRenderer = require('./view-renderer');
const onDOMElementsResized = require('client/src/utils/onDOMElementsResized');
const ScopedPolyfill = require('client/libraries/style-scoped-polyfill');
const { isTrue } = require('core/src/utils/validation');

/**
 * A container to display View renders
 * @param {string} id               The container ID.
 * @param {object} containerData
 * @returns {ViewContainer}
 */
const ViewContainer = function (id, containerData) {
	containerData = _.ensure(containerData, _.isObject, {});
	this.id = id;
	this.classes = ["graphileon-container"];
	this._listeners = {
		resize: [],
		ready: [],
		close: []
	};

	var classes = undefined;
	if (_.isString(containerData.classes)) {
		classes = containerData.classes.split(' ');
	} else if (_.isArray(containerData.classes)) {
		classes = containerData.classes;
	}
	if (classes !== undefined) {
		this.classes = this.classes.concat(classes);
	}

	this.element = this.build(containerData);
	this.$element = this.element;
	if(!isjQuery(this.$element)) this.$element = $(this.element);

	this.addCustomCSS(containerData.css);

	this.$element.one('remove', () => {
		// If container is suddenly removed from the DOM, assume the user is the cause
		this.close('user');
	});

	this._config = {};

	this._eventInterface = new EventInterface();
	this._eventInterface.extend(this);
	this.childrenContainerNames = []; // IDs of (view) containers that are DOM children of this container
};

ViewContainer.State = {
	MAXIMIZED: 'maximized',
	MINIMIZED: 'minimized',
	NORMALIZED: 'normalized',
	COLLAPSED: 'collapsed'
};

ViewContainer.prototype._state = ViewContainer.State.NORMALIZED;
ViewContainer.prototype._config = undefined;
ViewContainer.prototype._listeners = undefined;
ViewContainer.prototype._renderer = undefined;
ViewContainer.prototype._onRendererClose = function () {
};


ViewContainer.prototype.id = undefined;
ViewContainer.prototype.content = undefined;
ViewContainer.prototype.element = undefined;
ViewContainer.prototype.$element = undefined; // jQuery version of same element
ViewContainer.prototype.classes = undefined;
ViewContainer.prototype.properties = undefined;
ViewContainer.prototype.index = undefined;
ViewContainer.prototype.isNew = undefined; // current content is the first content of this container


/**
 * Builds the container element. Override for specific containers.
 * @param {string} containerID
 * @param {object} containerData
 * @param {string[]} classes
 * @returns {jQuery} The container element, as a jQuery object
 */
ViewContainer.prototype._build = function (containerID, containerData, classes) {
	var self = this;
	containerData = _.ensure(containerData, _.isObject, {});

	const title = containerData['title'];
	const collapsable = containerData['collapsable'];
	const closable = containerData['closable'];
	const headerVisible = containerData['headerVisible'];
	const headerClass = containerData['headerClass'];
	const borderVisible = containerData['borderVisible'] || false;

	let header = false;
	if (!_.isEmpty(_.trim(title, ' ')) || collapsable || closable) {
		header = true;
	}
	if (!_.isNil(headerVisible) && !headerVisible) {
		header = false;
	}

	let borderClass = 'border-0';
	if (borderVisible) {
		borderClass = '';
	}

	// CONTAINER
	var containerdiv = $(`<div class="default card ${borderClass}">`);

	// HEADER
	if (header) {
		const headerEl = $(`<div class="container-header card-header d-flex border-0 ${headerClass}">`);

		const titleArea = $(`
			<div class="w-100 text-secondary me-4">
				<label class="title"></label>
			</div>`);

		const actionButtons = $('<div class="d-flex align-items-center">');

		// Collapse button
		if (containerData['collapsable']) {
			containerdiv.addClass('collapsable-container');

			if (containerData['collapsed']) {
				containerdiv.addClass('collapsed');
			}

			let collapseButton = $(`
				<div class="me-1">
					<i class="fa clickable collapse-container-button d-flex align-items-center" data-action="switch-collapsable"></i>
				</div>`);
			actionButtons.append(collapseButton);

			collapseButton.click(() => {
				const state = containerdiv.hasClass('collapsed') ? ViewContainer.State.NORMALIZED : ViewContainer.State.COLLAPSED;
				this.updateState('state', state);
			});
		}

		// Close button
		if (containerData['closable']) {
			actionButtons.append(`
				<div>
					<i class="fa fa-times clickable d-flex align-items-center" data-action="close-container"></i>
				</div>`);
			actionButtons.on('click', '[data-action="close-container"]', function () {
				self.close('user');
			});
		}

		headerEl.append(titleArea);
		headerEl.append(actionButtons);
		containerdiv.append(headerEl);
	}

	// CONTENT
	var contentWrapper = $('<div class="container-body card-body">');
	var content = $('<div class="container-content h-100 w-100">');
	contentWrapper.append(content);
	// Listen to resize events
	this.contentResizeObserver = onDOMElementsResized(content, () => self.resize());
	containerdiv.append(contentWrapper);

	containerdiv.attr('id', containerID);
	for (var i in classes) {
		containerdiv.addClass(classes[i]);
	}

	return containerdiv;
};

/**
 * Checks whether the container is still available, or removed. Override for specific containers.
 * @returns {boolean}
 */
ViewContainer.prototype._isAvailable = function () {
	var find = document.getElementById(this.id);
	return find !== null;
};

/**
 * Attaches the container to an area. Override for specific containers.
 * @param {jQuery} container    The container
 * @param {jQuery} area         The area to attach to, as a jQuery object.
 * @param {jQuery} [before]     [optional] The element to place the container before.
 */
ViewContainer.prototype._attachTo = function (container, area, before) {
	if (before !== undefined) {
		before.before($(container));
	} else {
		area.append($(container));
	}
};

ViewContainer.prototype._setTitle = function (element, title) {
	var $title = $(element).find('.title');
	$title.html(title);
	if (_.def(title)) {
		$title.show();
	} else {
		$title.hide();
	}
};
ViewContainer.prototype._close = function (element) {
	$(element).remove();
	if (this.contentResizeObserver) {
		this.contentResizeObserver.disconnect();
	}
};
ViewContainer.prototype._setSize = function (element, width, height) {
	if (! _.isNil(width)) {
		$(element).css('width', (width >= 0 ? width + "px" : width));
	}

	if (! _.isNil(height)) {
		$(element).css('height', (height >= 0 ? height + "px" : height));
	}
};
ViewContainer.prototype._setAttribute = function (element, attribute, value) {
	$(element).attr(attribute, value);
};
ViewContainer.prototype._getInnerSize = function (element) {
	var content = $(element).find('.container-content');
	return {
		width: content.width(),
		height: content.height()
	};
};
ViewContainer.prototype._setState = function (element, state) {
	if(state === ViewContainer.State.COLLAPSED) {
		$(element).addClass('collapsed');
	} else {
		$(element).removeClass('collapsed');
	}
};
ViewContainer.prototype._focus = function (element) {
	// No default implementation
};

ViewContainer.prototype._moveOnTop = function (element) {
	// No default implementation
};

ViewContainer.prototype._moveOnBottom = function (element) {
	// No default implementation
};


/**
 * Get the unique ID of this container.
 * @returns {undefined}
 */
ViewContainer.prototype.getId = function () {
	return this.id;
};

/**
 * Checks whether the container is still available on the page, or has been removed.
 * @returns {boolean}
 */
ViewContainer.prototype.isAvailable = function () {
	return this._isAvailable();
};

/**
 * Builds the container.
 * @param {object} containerData The container identifier.
 * @returns {jQuery}
 */
ViewContainer.prototype.build = function (containerData) {
	const built = this._build(this.id, containerData, this.classes);
	this.setConfig(containerData);

	if (!isjQuery(built) && !(built instanceof HTMLElement)) {
		log.error("Custom container build function did not return a jQuery object or HTMLElement");
		return $('');
	}
	return built;
};

ViewContainer.prototype.addCustomCSS = function(css) {
	if (! _.isString(css) || ! (css.length > 0)) {
		return;
	}

	var style = $('<style type="text/css">').text(css);
	this.$element.prepend(style);
	var scope = ScopedPolyfill.scopeStyle(style[0]);
	this.$element.addClass(scope);
}

/**
 * Attaches the container to the given area.
 * @param {jQuery} area The area as a jQuery object
 */
ViewContainer.prototype.attachTo = function (area) {
	if (!isjQuery(area)) {
		log.error("Area parameter is not a jQuery object.");
		return false;
	}

	var index = this.index ? this.index : 0;
	var before = undefined;
	var valid = _.validate({
		index: [index, _.isStringOrNumber(index), "Must be string or number", {
			default: 0,
			warn: index !== undefined
		}]
	}, "Will not use index.").isValid();
	if (valid !== false) {
		var indexes = _.getFromjQueryCollection(area.children(), 'attr', ['data-index']);
		for (var i in indexes) {
			if (indexes[i] === undefined) {
				indexes[i] = 0;
			}
		}
		indexes.push(index);
		indexes.sort(_.compareIndex);
		before = area.children().eq(_.findLastIndex(indexes, i => i === index));
		if (before.length === 0) {
			before = undefined;
		}
	}

	this._attachTo(this.element, area, before);

	return true;
};

/**
 * Sets the title of the container.
 * @param {string} title
 * @returns {undefined}
 */
ViewContainer.prototype.setTitle = function (title) {
	this._setTitle(this.element, title);
};

/**
 * Set the order index of the container
 * @param [string|Number] index
 */
ViewContainer.prototype.setIndex = function (index) {
	this.index = index;
	if (index === undefined) {
		this.$element.removeAttr('data-index');
	} else {
		this.$element.attr('data-index', index);
	}
};

/**
 * Sets the (preferred) size of the container
 * @param {number} width
 * @param {number} height
 */
ViewContainer.prototype.setSize = function (width, height) {
	if(this._setSize(this.element, width, height) !== false) {
		this.resize(); // notify listeners
	}
	if (_.def(height)) {
		this.$element.addClass('container-fixed-height');
		this.$element.removeClass('container-content-height');
	} else {
		this.$element.removeClass('container-fixed-height');
		this.$element.addClass('container-content-height');
	}
};

/**
 * Removes the container without notifying anyone.
 */
ViewContainer.prototype.destroy = function() {
	this._close(this.element);
};

/**
 * Closes the container.
 * @param {string} origin    The origin of the close event.
 * @returns {undefined}
 */
ViewContainer.prototype.close = function (origin) {
	this._closeRenderer(origin);
	this.fire('close', origin);
	this.destroy();
};

/**
 * Removes all content from the Container and detaches the renderer.
 * @param {string} origin    The origin of the close event.
 */
ViewContainer.prototype.empty = function (origin) {
	this.setTitle('Empty');

	this._closeRenderer(origin);
	this.setContent('');
};

ViewContainer.prototype.setAttribute = function (attribute, value) {
	this._setAttribute(this.element, attribute, value);
};

ViewContainer.prototype.getInnerSize = function () {
	var innerSize = this._getInnerSize(this.element);
	var width = _.get(innerSize, 'width');
	var height = _.get(innerSize, 'height');

	var valid = _.validate('getInnerSize', {
		height: [height, 'isNumber'],
		width: [width, 'isNumber']
	}, "Invalid size returned by Container's getInnerSize.").isValid();

	if (! valid) return {height: 0, width: 0};

	return {width, height};
};

/**
 * Set the content of this container.
 * @param {jQuery|string} content   Anything that can be used as html.
 * @param {ViewRenderer} [renderer]     [optional] If set, the renderer will be connected to important events, such as close, resize, etc.
 * @returns {jQuery|boolean}
 */
ViewContainer.prototype.setContent = function (content, renderer) {
	this.content = content;

	if(renderer) {
		this.attachRenderer(renderer);
	}

	if(isjQuery(content)) content = content[0];
	return this._setContent(this.element, content);
};
/**
 * Sets the content for the container. Override for specific containers.
 * @param {jQuery} element          The container element, as a jQuery object.
 * @param {jQuery|string} content   Anything that can be used as html.
 */
ViewContainer.prototype._setContent = function (element, content) {
	// HTML view can have .contaner-content inside so we need only the parent one
	$(element).find('.container-content').first().html(content);
};

ViewContainer.prototype.getContent = function() {
	return this.content;
};

ViewContainer.prototype.setOriginFunctionCallback = function (callback) {
	this._setOriginFunctionCallback(this.element, callback);
};

ViewContainer.prototype._setOriginFunctionCallback = function (element, callback) {
	let button = $('<div class="graphileon-origin-function-button fa fa-cog">');
	button.on('click', callback);
	$(element).append(button[0]);
};

ViewContainer.prototype.detachRenderer = function () {
	var renderer = this._renderer;
	this._detachRenderer();
	return renderer;
};

ViewContainer.prototype.checkRenderer = function(renderer) {
	checkMethods(renderer, [
		'on', 'close', 'removeListener', 'resize', 'ready', 'getFunctionID', 'getInstanceID'
	], 'renderer');
};

ViewContainer.prototype.attachRenderer = function (renderer) {
	this.checkRenderer(renderer);

	if (this._renderer) {
		log.error("A renderer was already attached to this container.");
		return false;
	}

	if (! (renderer)) {
		log.error('ViewContainer.attachRenderer: Expecting ViewRenderer instance as parameter');
		return false;
	}

	this._onRendererClose = origin => this.close(origin);
	this._onComponentClose = origin => this.close(origin);
	this._onComponentContainerChange = specs => this.update(specs);

	renderer.on('Close', this._onRendererClose, true);
	renderer.on(VueView.Event.Out.COMPONENT_CLOSED, this._onComponentClose);
	renderer.on(VueView.Event.Out.CONTAINER_CHANGED, this._onComponentContainerChange);

	this._renderer = renderer;
};

/**
 *
 * @param {string} origin    The origin of the close event
 * @private
 */
ViewContainer.prototype._closeRenderer = function (origin) {
	if (this._renderer) {
		var renderer = this._renderer;
		this._detachRenderer();
		renderer.close(origin);
	}
};

ViewContainer.prototype._detachRenderer = function () {
	if (this._renderer) {
		this._renderer.removeListener('Close', this._onRendererClose);
		this._renderer.removeListener(VueView.Event.Out.COMPONENT_CLOSED, this._onComponentClose);
		this._renderer.removeListener(VueView.Event.Out.CONTAINER_CHANGED, this._onComponentContainerChange);
		this._renderer = undefined;
	}
};

/**
 * Notify all listeners that the container has been resized.
 * @returns {undefined}
 */
ViewContainer.prototype.resize = function () {
	var innerSize = this.getInnerSize();
	var width = innerSize.width;
	var height = innerSize.height;

	if (! (width >= 0 && height >= 0)) {
		return;
	}

	this.fire('resize', {width: width, height: height});

	if (this._renderer instanceof ViewRenderer) {
		this._renderer.resize(width, height);
	}
};

/**
 * Notify all listeners that the container is ready.
 */
ViewContainer.prototype.ready = function () {
	this.fire('ready');
	if(this._renderer) {
		this._renderer.ready();
	}
};

/**
 * Get the ID of the Function that currently has an instance represented in this container.
 * @returns {*}
 */
ViewContainer.prototype.getCurrentFunctionID = function () {
	if (this._renderer instanceof ViewRenderer) {
		return this._renderer.getFunctionID();
	} else {
		return null;
	}
};

/**
 * Get the ID of the instance that is currently represented in the container.
 * @returns {*}
 */
ViewContainer.prototype.getCurrentInstanceID = function () {
	if (this._renderer instanceof ViewRenderer) {
		return this._renderer.getInstanceID();
	} else {
		return null;
	}
};

ViewContainer.prototype.focus = function () {
	this._focus(this.element);
};

/**
 * Set the container state.
 * @param {string} state    See ViewContainer.State for options
 * @param {boolean} force    Force state change even if it's the same state.
 * @returns {boolean}        True if the state changed, false if not (no call will be made to container's _setState).
 */
ViewContainer.prototype.setState = function (state, force = false) {
	if (this._state === state && force !== true) {
		return false;
	}
	this._state = state;

	this.isUpdating = true;
	this._setState(this.element, state);
	this.isUpdating = false;
	return true;
};

/**
 * Call a custom defined function (if defined) when a property changes
 * @param {object} config
 * @returns {boolean}
 */
ViewContainer.prototype.setConfig = function (config = {}) {
	this.config = config;

	return true;
};

ViewContainer.prototype.update = function(config) {
	checkType(config, 'object', 'specs');

	this.setTitle(config.title);
	this.setState(config.state);


	// Detect size changes by trigger
	let currentSize = this.getInnerSize();
	let newHeight;
	let newWidth;

	if (config.height !== this.config.height) {
		newHeight = config.height;
	}

	if (config.width !== this.config.width) {
		newWidth = config.width;
	}

	// if size changed by trigger apply changes
	if (! _.isNil(newWidth) || ! _.isNil(newHeight)) {
		this.setSize(newWidth || currentSize.width, newHeight || currentSize.height);
	}

	if (isTrue(_.get(config, 'onTop'))) {
		this.moveOnTop();
	}

	if (isTrue(_.get(config, 'onBottom'))) {
		this.moveOnBottom();
	}

	this.setConfig(config);
};

/**
 * Updates the `container` property of the view model.
 * @param {string} property        Property of `container` in the model (e.g. `id` or `state`).
 * @param value                    Value to set it to
 */
ViewContainer.prototype.updateState = function (property, value) {
	// 1. Prompts don't have renderers
	// 2. Container should not try to update model if container is applying updates from the model, to prevent infinite loops
	if (this._renderer && !this.isUpdating) {
		this._renderer.setContainerState(property, value);
	}
};

ViewContainer.prototype.isInDOM = function() {
	if (! _.isElement(_.get(this.$element, '0')))
		return false;

	return document.body.contains(this.$element[0]);
};


ViewContainer.prototype.moveOnTop = function() {
	if (! this.element) return;
	this._moveOnTop(this.element);
};

ViewContainer.prototype.moveOnBottom = function() {
	if (! this.element) return;
	this._moveOnBottom(this.element);
};

module.exports = ViewContainer;
