"use strict";

const $ = require('jquery');
const _ = require('core/src/utils/legacy');
const log = require('core/src/log').instance("function/jointjsgraphview/renderer");

const ViewRenderer = require('client/src/view-renderer');
const GraphStyles = require('core/src/graph/graph-styles').default;
const IFactory = require('core/src/i-factory').default;
const Registry = require('core/src/registry').default;
const { GraphRegistry, isGraph } = require('core/src/graph/i-graph');
const { GraphFilters } = require('client/src/graph/graph-filters');
const { hasChangesInChildren, findChangeFromRoot } = require('core/src/utils/changes');
const { isTrue } = require('core/src/utils/validation');
const Profiler = require('utils/src/profiler');
const Language = require("core/src/language").default;
const IGraphileon = require("core/src/i-graphileon").default;
const APIClientAbstract = require('core/src/api-client-abstract').default;
const bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min');

/**
 * @typedef {{id:string|number, labels:string[], properties?:object, x?:number, y?:number, fixed?:boolean, meta?:{store?:string}}} Node
 * @typedef {{id:string|number, type:string, properties?:object, source:string|number, target:string|number, meta?:{store?:string}}} Relation
 * @typedef {Node & {children:(string|number)[]}} Group
 */

class JointJSGraphViewRenderer extends ViewRenderer {

	constructor(dependencies) {
		super(dependencies);
		this.dependencies = dependencies;
		this.language = dependencies.get(Language);
		this.graphileon = dependencies.get(IGraphileon);
		this.api = dependencies.get(APIClientAbstract);

		/**
		 * ID of this instance
		 * @type {Number}
		 */
		this.id = _.uniqueId();
		/**
		 * The JointJS Graph instance
		 * @type {JointJS Graph}
		 */
		this.graph = null;
		/**
		 * Navigator element
		 * @type {JointJS UI Navigator}
		 */
		this.navigatorEl = null;
		/**
		 * Scroller element
		 * @type {JointJS UI Scroller}
		 */
		this.paperEl = null;
		
		/**
		 * The state of the model the renderer is currently showing
		 * @type {{}}
		 */
		this.model = {};

		this.loading = new Promise((resolve, reject) => {
			this.resolveLoading = resolve;
			this.rejectLoading = reject;
		});

		/**
		 *
		 * @type {GraphFilters}
		 */
		this.filters = null;

		this.factory = dependencies.get(IFactory);
		this.styles = dependencies.get(GraphStyles);
		this.registry = dependencies.get(Registry);
		this.registry.require(GraphRegistry, isGraph);

		this.localStyles = this.styles.extend();
		this._autoCompleteStatus = false;
	}

	/**
	 * Get nodes from model.
	 * @returns {Node[]}
	 */
	getNodes() {
		return this.model.nodes;
	}

	/**
	 * Get relations from model
	 * @returns {Relation[]}
	 */
	getRelations() {
		return this.model.relations;
	}

	/**
	 * Get nodes that do not have their filter property set to `false`.
	 * @returns {Node[]}
	 */
	getFilteredNodes() {
		return _.filter(this.model.nodes, node => node[this.model.filterProperty] !== false);
	}

	/**
	 * Get relations that do not have their filter property set tot `false`.
	 * @returns {Relation[]}
	 */
	getFilteredRelations() {
		return _.filter(this.model.relations, rel => rel[this.model.filterProperty] !== false);
	}

	/**
	 * Get x,y coordinate of given node in the graph.
	 * @param {Node} node
	 * @returns {{x:number, y:number}|false}
	 */
	getPosition(nodeId) {
		if (! nodeId) {
			return false;
		}
		const node = _.find(this.model.nodes, {id: nodeId});
		if (! node) {
			return false;
		}
		if (_.isFinite(node.x) && _.isFinite(node.y)) {
			return {x: node.x, y: node.y};
		}

		if (_.isFinite(node.px) && _.isFinite(node.py)) {
			return {x: node.px, y: node.py};
		}

		return false;
	}

	/**
	 * Set x,y coordinate of given node in the graph.
	 * @param {Node} node
	 * @returns {{x:number, y:number}|false}
	 */
	setPosition(id, x, y) {
		console.warn('Setting node position: ', {id, x, y});
		const node = _.find(this.model.nodes, {id});
		node.x = x;
		node.y = y;

		this.requestUpdate({"_update.change": {nodes: [node]}});
	}

	/**
	 * Get unique index of the given entity.
	 * @param {Node|Relation} entity
	 * @returns {string}
	 */
	getIndex(entity) {
		// If it seems like a relationship include source and target in the index
		// If one changes this means that it is a "new" relationship even if it has the same id and store
	}

	/**
	 * Get selected nodes from visualization.
	 * @returns {Tag[]|*[]}
	 */
	getSelectedNodes() {
		
	}

	/** @private */
	initDOM() {
		const self = this;
		// Main graph element
		this.paperEl = $("<div class='paper'/>");
		this.navigatorEl = $("<div class='navigator-container'/>");

		// Wrapper element for all of the components
		this.wrapperEl = $("<div class='appBody'/>")
			.css({
				"position": "relative",
			    "width": "100%",
			    "height": "100%",
			    "box-sizing": "border-box",
			    "margin": "0",
			    "padding": "0",
			    "display": "flex",
			    "flex-direction": "column",
			    overflow: "hidden",
			})
			.append(this.paperEl)
			.append(this.navigatorEl);

		this.loader = $('<div class="fa fa-spinner fa-spin"></div>').css({
			position: 'absolute',
			'font-size': '2rem',
			top: '50%',
			left: '50%',
			'margin-left': '-1.5rem'
		})

		this.wrapperEl.append(this.loader);

		$(this.wrapperEl).ready(function() {
			//self.createToolbar();
		})
	}

	/* @private */
	setupEvents(graph) {
		const addEventListener = event => {
			let method = `on${_.upperFirst(event)}`;
			if (!_.isFunction(this[method])) {
				log.error(`Cannot listen to '${event}' event. JointJsGraphViewRenderer.${method} is not a function.`);
				return;
			}
			graph.on(event, this[method].bind(this));
		};
		addEventListener('nodeClick');
		addEventListener('nodeRightClick');
		addEventListener('nodeDoubleClick');
		graph.on('edgeClick', this.onRelationClick.bind(this));
		graph.on('edgeRightClick', this.onRelationRightClick.bind(this));
		graph.on('edgeDoubleClick', this.onRelationDoubleClick.bind(this));
		//addEventListener('groupClick');
		//addEventListener('groupRightClick');
		//addEventListener('groupDoubleClick');
		addEventListener('canvasClick');
		addEventListener('canvasRightClick');
		//addEventListener('canvasDoubleClick');
		//addEventListener('click');
		//addEventListener('delete');
		//addEventListener('link');
		//addEventListener('linkToCanvas');
		addEventListener('selection');
		addEventListener('localUpdate');
		//addEventListener('relationSourceChanged');
		//addEventListener('relationTargetChanged');
		//addEventListener('zoomToFitChanged');
	}

	/**
	 * Show/hide the loader indicating the view is not ready.
	 * @param {boolean} show
	 */
	showLoader(show = true) {
		show ? this.loader.show() : this.loader.hide();
	}

	/** @private */
	async initGraph(model) {
		this.showLoader(true);
		try {
			const JointJSGraph = (await import('./jointjs-graph-view-renderer/jointjs-graph')).default;
			this.showLoader(false);

			this.graph = new JointJSGraph({
				paperEl: this.paperEl,
				navigatorEl: this.navigatorEl,
				appBody: this.appBody,
				elements: {nodes: this.getNodes(), relations: this.getRelations()},
				getPosition: this.getPosition.bind(this),
				styles: this.styles,
				zoomToFitStatus: _.get(model, 'zoomToFitStatus', false)
			});
			this.setupEvents(this.graph);
			this.resolveLoading(this.graph);
		} catch (err) {
			console.error('JoinJS Graph Loader Error: ', err);
		}
	}

	doRender(model) {
		this.model = model;

		this.initDOM();
		this.initGraph(model);

		const initialChanges = _.mapValues(model, value => ({
			old: undefined,
			new: value
		}));

		this.doUpdate(model, initialChanges);

		// Register
		this.registry.add(GraphRegistry, this);

		return this.wrapperEl;
	}

	async doUpdate(model, changes, mutationId) {
		await this.loading;

		Profiler.start("jjsgraphview-view-renderer.doUpdate");
		this.model = model;

		/*if(hasChangesInChildren(changes, 'filters')) {
			this.filters.loadFromSimpleList(model.filters);
		}

		this.filters.updateAutoFilters();
		this.filters.updateInterface();

		if (this.filters.filterGraph()) {
			// If items are filtered in graph a new NetworkViewRenderer.doUpdate will be issued
			// through the NetworkViewRenderer.filter method and we will revisit the rest later
			return;
		}
		*/
		Profiler.start("jjsgraphview-view-renderer.doUpdate.styles");

		// Extend styles with used styles and styles parameter
		this.localStyles = this.styles;
		if(model.usedStyles && model.usedStyles.length) {
			this.localStyles = new GraphStyles(this.dependencies); // Specific styles nodes are used so don't use user styles
			_.forEach(model.usedStyles, stylesNode => {
				this.localStyles = this.localStyles.extend(GraphStyles.fromStylesNode(this.dependencies, stylesNode));
			});
		}
		// Extend styles with specifically defined styles on YFilesView
		this.localStyles = this.localStyles.extend(GraphStyles.unflatten(model.styles));

		//this.updateStyles();

		Profiler.stop("jjsgraphview-view-renderer.doUpdate.styles");
		Profiler.start("jjsgraphview-view-renderer.doUpdate.data");
//		this.graph.setLayoutOptions(model.layoutOptions);

		if (_.some(['nodes', 'relations', 'groups', 'layout', 'styles'], this.hasChangedIn(changes))) {
			this.graph.setData({
				nodes: model.nodes,
				edges: model.relations,
				groups: model.groups
			});
		}
		Profiler.stop("jjsgraphview-view-renderer.doUpdate.data");

		this.graph.showOverview(model.showOverview);
		this.graph.setupSelection();
/*
		this.graph.setSelectionMode(this._selectionModes[this._selectionMode]);

		// Update layout selection
		if(this.layoutSelectEl) {
			this.layoutSelectEl.val(model.layout);
		}

		// Check/uncheck overview
		let overviewChecked = model.showOverview === undefined || _.hasBooleanValue(model.showOverview, true);
		this.overviewCheck.prop('checked', overviewChecked);

		// Check/uncheck autocomplete
		this.setButtonActiveStatus(this._autoCompleteStatus, "jjsgraphview-view-autocomplete");

		this.graph.setSelection({
			nodes: model.state.selected.nodes,
			edges: model.state.selected.relations,
			groups: model.state.selected.groups
		});

		this.registry.notifyChange(GraphRegistry, this);
*/
		this._autoCompleteStatus = model.autoCompleteStatus === undefined || _.hasBooleanValue(model.autoCompleteStatus, true);
		Profiler.stop("jjsgraphview-view-renderer.doUpdate");

		this.graph.setZoomToFit(model.zoomToFitStatus);
		this.doResize();

		// Focusing when jsPanel is colapsed breaks jsPanel header so we prevent that
		// const graphVisibleSize = this.graph.paperEl[0].getBoundingClientRect();
		/* 
		if (graphVisibleSize.height) {
			// Focus to be able to get keyboard keys status
			this.paperEl.focus();
		}
		*/
	}

	/**
	 * Recalculate and apply styles to all entities in the graph.
	 */
	updateStyles() {
		Profiler.start("jjsgraphview-view-renderer.updateStyles.final");
		_.forEach({
			nodes: 'node',
			relations: 'relation',
			groups: 'group'
		}, (entityType, collection) => {
			_.forEach(this.model[collection], entity => {
				delete entity.style;
				entity.style = this.localStyles.computeStyles(entity, entityType, false);
				entity.finalStyle = this.localStyles.evaluateStyles(entity.style, entity, this.model.stylesEvaluation === 'explicit');
				GraphStyles.reviseStyles(entity.finalStyle, entity, entityType);
			})
		});
		Profiler.stop("jointjs-graph-view-renderer.updateStyles.final");

		_.forEach(this.model.nodes, entity => {
			_.forEach(this.model.nodeTemplates, (template, selector) => {
				const match = GraphStyles.matchSelector(entity, selector, 'node');
				if (!match) {
					return;
				}
				
				if (_.isNil(entity.nodeTemplate)) {
					entity.nodeTemplate = template
				}
				
				if (entity.selector && selector.length > entity.selector.length) {
					entity.nodeTemplate = template
				}

				entity.selector = selector;
			})
			delete entity.selector;
		});

		if(this.graph) {
			Profiler.start("jjsgraphview-view-renderer.updateStyles.graph");
			this.graph.updateStyles();
			Profiler.stop("jointjs-graph-view-renderer.updateStyles.graph");
		}
	}

	async doResize() {
		await this.loading;
		if(this.graph.zoomToFitStatus) {
			_.defer(() => this.graph.setZoomToFit());
		}
	}

	onClose() {
		this.registry.remove(GraphRegistry, this);
	}

	onNodeClick(event) {
		this.trigger({
			type: 'nodeClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	/**
	 * Handle the event in which changes to entities occurred in the graph, which should be applied to the model.
	 * @param {{nodes?:Node[], edges?:Relation[], groups?:Group[]}} updates
	 */
	onLocalUpdate(updates) {
		if('edges' in updates) {
			updates.relations = updates.edges;
			delete updates.edges;
		}

		const changes = {};
		_.forEach(Object.keys(updates), category => {
			changes[category] = updates[category].map(update => ({...update.update, ...this.getIdentifier(update.entity)}))
		});
		this.requestUpdate({"_update.change": changes});
	}

	async onNodeRightClick(event) {
		await this.onSelectionUpdate;
		if (!this.openContextMenu('node', event.data)) {
			this.trigger({
				type: 'nodeRightClick',
				mouse: ViewRenderer.mouse,
				...event
			});
		}
	}

	onNodeDoubleClick(event) {
		if(this.model.explorable) {
			this.event("findNeighbours", [event.data.id]);
		}
		this.trigger({
			type: 'nodeDoubleClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onRelationClick(event) {
		this.trigger({
			type: 'relationClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	async onRelationRightClick(event) {
		await this.onSelectionUpdate;
		if (!this.openContextMenu('relation', event.data)) {
			this.trigger({
				type: 'relationRightClick',
				mouse: ViewRenderer.mouse,
				...event
			});
		}
	}

	onRelationDoubleClick(event) {
		this.trigger({
			type: 'relationDoubleClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onCanvasClick(event) {
		this.trigger({
			type: 'canvasClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onCanvasRightClick(event) {
		const position = event.position;
		position.graphX = position.x;
		position.graphY = position.y;
		if (!this.openContextMenu('canvas', position)) {
			this.trigger({
				type: 'canvasRightClick',
				mouse: ViewRenderer.mouse,
				...event
			});
		}
	}

	onCanvasDoubleClick(event) {
		this.trigger({
			type: 'canvasDoubleClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onClick(event) {
		const data = $(event.el).data();
		data.node = event.node;
		this.trigger({
			type: 'click',
			data: data
		});
	}

	onDelete(event) {
		this.requestUpdate({
			"_update.remove": {
				nodes: event.nodes,
				relations: event.edges,
				groups: event.groups
			}
		});
	}

	/**
	 * Handle the event in which the user links one node to another (by drawing an edge).
	 * @param event
	 */
	onLink(event) {
		this.trigger({
			type: 'link',
			...event
		});
	}

	onSelection(event) {
		this.onSelectionUpdate = this.requestUpdate({
			state: {selected: {nodes: event.nodes, relations: event.edges, groups: event.groups}}
		});
	}

	/**
	 * Handle the event in which any of the filters changed.
	 * @param {{nodes?:Node[], relations?:Relation[], groups?:Group[]}} visible
	 */
	onFilter(visible) {
		this.requestUpdate({
			'state.visible': visible
		});
	}

	/**
	 * Create an object with properties that, together, uniquely identify the given entity.
	 * @param {Node|Relation} entity
	 * @returns {object}
	 */
	getIdentifier(entity) {
		return _.pick(entity, ['id', 'meta.store']);
	}

	/**
	 * Open an instance of NetworkStylesView
	 */
	openStylesWindow() {
		this.factory.executeFunction({
			_functionName: 'NetworkStylesView',
			'$container.height': '95%',
			'$container.width': '50%',
			'$container.id' : 'myStyling',
			'name': 'My styles'
		});
	}

	/**
	 * Toggle the autoCompleteStatus in the model (which will then be propagated to the renderer).
	 */
	toggleAutoComplete() {
		this._autoCompleteStatus = !this._autoCompleteStatus;
		this.requestUpdate({ autoCompleteStatus: this._autoCompleteStatus });
	}

	/** @override */
	createContextMenus(contextMenus) {
		ViewRenderer.prototype.createContextMenus.call(this, contextMenus);

		this.addContextMenuItem(
			"canvas",
			100,
			this.language.translate("Remove selected nodes from view"),
			() => {
				let nodes = this.graph.getSelectedNodes();

				this.requestUpdate({
					"_update.remove": {
						// Remove the connected edges to these nodes also
						relations: this.graph.getConnectedEdges(nodes),
						nodes,
					}
				});
			}
		);

		this.addContextMenuItem(
			"canvas",
			200,
			this.language.translate("Insert selected nodes"),
			() => {
				const graphs = this.registry.get(GraphRegistry);

				let nodes = [];
				_.forEach(graphs, graph => {
					if(graph !== this) {
						nodes = nodes.concat(graph.getSelectedNodes());
					}
				});
				nodes = _.map(nodes, node => ({...node, fixed: false})); // trigger layout

				this.requestUpdate({
					"_update.add": { nodes }
				});
			}
		);

		this.addContextMenuItem(
			"node",
			100,
			this.language.translate("Remove from view"),
			node => {
				this.requestUpdate({
					"_update.remove": {
						nodes: [node],
						// Remove the connected edges to this node also
						relations: this.graph.getConnectedEdges(node)
					}
				});
			}
		);
		this.addContextMenuItem(
			"relation",
			100,
			this.language.translate("Remove from view"),
			relation => {
				this.requestUpdate({
					"_update.remove": {
						relations: [relation]
					}
				});
			}
		);
	}
}


JointJSGraphViewRenderer.viewType = 'JointJSGraphView';

export default JointJSGraphViewRenderer;
