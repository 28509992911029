
	import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";
	import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
	import ActionButtonRenderer from "client/src/renderers/action-button-renderer/action-button-renderer.vue";

	import { AgGridVue } from "ag-grid-vue";
	import Log from "utils/src/log";

	import _ from "lodash";
	const log = Log.instance("client/renderers/team-edit-view");

	export default {
		name: "team-edit",
		mixins: [VueRendererComponent],
		components: { VueBootstrapTypeahead, AgGridVue, ActionButtonRenderer, 'actionButtonRenderer': ActionButtonRenderer },
		data() {
			return {
				team: {
					id: null,
					properties: {
						name: null
					}
				},
				teamUsers: {},
				teamTeams: {},
				allUsers: {},
				allTeams: {},
				userTrash: {},
				teamTrash: {},
				selectedUser: null,
				selectedTeam: null,
				columnDefinitions: [],
				teamColumnDefinitions: [],
				frameworkComponents: null,
				defaultColumnDefinitions: {
					sortable: true,
					resizable: true
				},
				defaultUsersGridOptions: {
					rowHeight: 32,
				},
				defaultTeamsGridOptions: {
					rowHeight: 32,
				},
				error: undefined,
				success: undefined
			}
		},
		computed: {
			allUsersData() {
				return Object.values(this.allUsers);
			},
			allTeamsData() {
				return Object.values(this.allTeams);
			},
			teamUsersData() {
				return Object.values(this.teamUsers);
			},
			teamTeamsData() {
				return Object.values(this.teamTeams);
			}
		},
		methods: {
			async submit() {
				let result;
				try {
					result = await this.$graphileon.api.saveTeam(this.team);
					this.success = 'Team saved succesfully.';
				} catch (e) {
					this.error = e.message;
					throw e;
				}
				this.$emit('submit', this.team);
				// Assign after event triggered 
				this.team = result;

				let promises = [];
				let deletedUsers = [];

				// remove user which are already in trash
				_.forEach(this.userTrash, async (user) => {
					promises.push(new Promise(async resolve => {
						try {
							let result = await this.$graphileon.api.removeUserFromTeam({id: this.team.id, userId: user.id});
							this.$delete(this.teamUsers, user.id);
							this.$delete(this.userTrash, user.id);
							deletedUsers.push(user.id);
							resolve(result);
						} catch (e) {
							this.error = e.message;
							log.error(e);
						}
					}));

				});

				// remove teams which are already in trash
				_.forEach(this.teamTrash, async (team) => {
					promises.push(new Promise(async resolve => {
						try {
							let result = await this.$graphileon.api.removeTeamFromTeam({id: this.team.id, teamId: team.id});
							this.$delete(this.teamTeams, team.id);
							this.$delete(this.teamTrash, team.id);
							resolve(result);
						} catch (e) {
							this.error = e.message;
							log.error(e);
						}
					}));
				});

				let addedUsers = _.filter(this.teamUsers, {new: true});
				// save users which are already in teamUsers
				_.forEach(addedUsers, async (user) => {
					promises.push(new Promise(async resolve => {
						try {
							let result = await this.$graphileon.api.addUserToTeam({id: this.team.id, userId: user.id});
							this.teamUsers[user.id].new = false;
							resolve(result);
						} catch (e) {
							this.error = e.message;
							log.error(e);
						}
					}));
				});

				let addedTeams = _.filter(this.teamTeams, {new: true});
				// save teams which are already in teamUsers
				_.forEach(addedTeams, async (team) => {
					promises.push(new Promise(async resolve => {
						try {
							let result = await this.$graphileon.api.addTeamToTeam({id: this.team.id, teamId: team.id});
							this.teamTeams[team.id].new = false;
							resolve(result);
						} catch (e) {
							this.error = e.message;
							log.error(e);
						}
					}));
				});

				await Promise.all(promises);
				this.success = 'Team updated successfully.';

			},
			async remove() {
				let result = await this.$graphileon.viewmanager.confirm(
					this.t('Do you want to delete this team?'),
					{alertType: 'danger', headerText: this.t('Delete Team')}
				);
				if ( result ){
					await this.$graphileon.api.removeTeam(this.team.id);
					this.$emit('delete', this.team);
					this.close();
				}
			},
			addUser() {
				let newUser = _.clone(this.allUsers[this.selectedUser]);
				newUser.new = true;
				this.$set(this.teamUsers, this.selectedUser, newUser);
				this.$delete(this.userTrash, this.selectedUser);
			},
			addUserToTrash(userId) {
				this.$set(this.userTrash, userId, this.allUsers[userId]);
				this.$delete(this.teamUsers, userId);
			},
			addTeam() {
				let newTeam = _.clone(this.allTeams[this.selectedTeam]);
				newTeam.new = true;
				this.$set(this.teamTeams, this.selectedTeam, newTeam);
				this.$delete(this.teamTrash, this.selectedTeam);
			},
			addTeamToTrash(teamId) {
				this.$set(this.teamTrash, teamId, this.allTeams[teamId]);
				this.$delete(this.teamTeams, teamId);
			},

			generateColumnDefinitions() {
				this.columnDefinitions = [
					{ headerName: this.t('Username'), field: 'name', filter: 'agTextColumnFilter'},
					{ headerName: this.t('Email Address'), field: 'email', filter: 'agTextColumnFilter'},
					{
						headerName: this.t('Action'),
						field: 'id',
						filter: false,
						cellRenderer: 'actionButtonRenderer',
						cellRendererParams:{
							icon: 'times',
							label: this.t('Remove'),
							name: 'remove',
							style: 'danger',
							onClick: (data, indx) => {
								this.addUserToTrash(data.data.id);
							}
						},
						width: 100,
						resizable: false,
						suppressSizeToFit: true
					}
				];
				this.teamColumnDefinitions = [
					{ headerName: this.t('Team name'), field: 'name', filter: 'agTextColumnFilter', filterParams:{ filterOptions: ['contains'] } },
					{
						headerName: this.t('Action'),
						field: 'id',
						suppressMenu: true,
						filter: false,
						cellRenderer: 'actionButtonRenderer',
						cellRendererParams:{
							icon: 'times',
							label: this.t('Remove'),
							name: 'remove',
							style: 'danger',
							onClick: (data, indx) => {
								this.addTeamToTrash(data.data.id);
							}
						},
						width: 100,
						resizable: false,
						suppressSizeToFit: true
					}
				];
			},
			getRowNodeId(data) {
				return data.id;
			},
			onGridReady(params) {
				this.$gridApi = params.api;
				this.$columnApi = params.columnApi;
				this.$loadGridAPI.resolve(this.$gridApi);
			},
			onTeamGridReady(params) {
				this.$teamGridApi = params.api;
				this.$teamColumnApi = params.columnApi;
				this.$teamLoadGridAPI.resolve(this.$teamGridApi);
			},
			onFirstDataRendered() {
				setTimeout(()=>{ // calling in same event loop will error because grid is still rendering
					this.$gridApi.setSortModel([
						{colId: 'name', sort: 'asc'},
						{colId: 'email', sort: 'asc'}
					]);
				});
			},
			onResize() {
				if(this.$teamGridApi) {
					this.$teamGridApi.sizeColumnsToFit();
				}
				if(this.$gridApi) {
					this.$gridApi.sizeColumnsToFit();
				}
			}
		},
		mounted() {
			this.generateColumnDefinitions();
		},
		created() {
			this.$loadGridAPI = {};
			this.$loadGridAPI.promise = new Promise((resolve, reject)=> {
				this.$loadGridAPI.resolve = resolve;
				this.$loadGridAPI.reject = reject;
			});
			this.$teamLoadGridAPI = {};
			this.$teamLoadGridAPI.promise = new Promise((resolve, reject)=> {
				this.$teamLoadGridAPI.resolve = resolve;
				this.$teamLoadGridAPI.reject = reject;
			});
		},
		beforeMount(){
			this.frameworkComponents = {
				actionButtonRenderer: ActionButtonRenderer
			};
		}
	}
