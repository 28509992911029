var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "background",
      staticClass: "modal-background",
      style: _vm.backgroundStyle,
      on: {
        click: _vm.onBackgroundClick,
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.onBackgroundClick.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-container card",
          style: _vm.containerStyle,
          on: {
            click: _vm.onContainerClick,
            contextmenu: function ($event) {
              $event.preventDefault()
              return _vm.onContainerClick.apply(null, arguments)
            },
            mouseenter: _vm.onMouseEnter,
            mouseleave: _vm.onMouseLeave,
          },
        },
        [
          _vm.showHeader
            ? _c("div", { class: _vm.headerClass }, [
                _c(
                  "label",
                  {
                    staticClass: "title w-100 me-4",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _vm._v(" "),
                this.hasCloseButton
                  ? _c("i", {
                      staticClass:
                        "fa fa-times clickable close-container-button",
                      attrs: { "data-action": "close-container" },
                      on: { click: _vm.close },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "card-body", style: _vm.contentStyle }, [
            _c("div", {
              ref: "content",
              staticClass: "container-content h-100 w-100",
              attrs: { slot: "content" },
              slot: "content",
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }